:root {
    --totalTuple: 1;
    --slideUpHeight: 198;
    --slideDownHeight: "196px";
}
.modal {
    height: 115px;
    width: 870px;
    position: absolute;
    bottom: 32px;
    display: flex;
    justify-content: center;
    opacity: 1;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.5) 41.13%, #eff5fc 100%);
    border-radius: 0px 0px 4px 4px;
}
.outer {
    height: auto;
}
.animation {
    height: 196px;
    animation: slideDown 0.9s ease-in-out forwards;
}
.notAnimate {
    height: 196px;
    animation: slideUp 0.9s ease-in-out forwards;
}
@keyframes slideDown {
    0% {
        height: var(--slideDownHeight);
        // width: 886px;
        margin-left: 0px;
        margin-right: 0px;
    }
    70% {
        height: calc(var(--totalTuple) + 20px);
        margin-left: -8px;
        margin-right: -8px;
    }
    100% {
        height: var(--totalTuple);
        margin-left: -8px;
        margin-right: -8px;
    }
    // 100% {
    //     height: auto;
    //     margin-left: -8px;
    //     margin-right: -8px;
    // }
}
@keyframes slideUp {
    0% {
        height: var(--totalTuple);
        margin-left: -8px;
        margin-right: -8px;
    }
    70% {
        height: calc(var(--slideUpHeight) - 20px);
        margin-left: 0px;
        margin-right: 0px;
    }
    100% {
        height: var(--slideUpHeight);
        margin-left: 0px;
        margin-right: 0px;
    }
}
.tupleSlideDown {
    animation: tupleSlideDown 0.7s ease-in-out;
}
.tupleSlideUp {
    height: 104px;
    animation: tupleSlideUp 0.7s ease-in-out;
}
@keyframes tupleSlideDown {
    0% {
        height: 104px;
        // width: 886px;
    }
    99% {
        height: var(--totalTuple);
        // width: 912px;
    }
    100% {
        height: auto;
        // width: 912px;
    }
}
@keyframes tupleSlideUp {
    0% {
        height: var(--totalTuple);
        // width: 912px;
    }
    100% {
        height: 104px;
        // width: 886px;
    }
}
.b--16 {
    bottom: -16px;
}
.gap-8 {
    gap: 8px;
}
.viewButton-border {
    border: 1px solid #3366cc;
    border-radius: 20px;
}
.w-174 {
    width: 174px;
}
.c-3366cc {
    color: #3366cc;
}
.badge-animation{
    animation: infinite-translate 2.5s linear infinite;
    animation-iteration-count: 3;
}

@keyframes infinite-translate {
    0%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(800%);
    }
    
}
